@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
@import '@/stylesheets/base/accessibility.scss';
.account-layout__nav {
  padding: 20px 15px;
  background-color: $red;
}

.account-layout__nav-headline {
  @include visually-hidden;
}

.account-layout__nav-list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.account-layout__nav-item {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.account-layout__link {
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 18px;
  text-decoration: none;
  color: $white;
  &::before {
    content: '';
    margin-top: -2px;
  }
  &:focus {
    outline: 0;
  }
  &:focus,
  &:hover {
    .account-layout__label {
      border-bottom: 1px solid $white;
    }
  }
}

.account-layout__link--home {
  &::before {
    width: 24px;
    height: 24px;
    background-image: url('~static/images/icon-home.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 48px;
  }
  &:focus,
  &.account-layout__link--active {
    &::before {
      background-position: -24px;
    }
  }
}

.account-layout__link--profile {
  &::before {
    width: 24px;
    height: 24px;
    background-image: url('~static/images/icon-user.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 48px;
  }
  &:focus,
  &.account-layout__link--active {
    &::before {
      background-position: -24px;
    }
  }
}

.account-layout__link--inbox {
  margin-left: 3px;
  padding-top: 5px;
  &::before {
    width: 24px;
    height: 18px;
    background-image: url('~static/images/icon-envelope.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 48px;
  }
  &:focus,
  &.account-layout__link--active {
    &::before {
      background-position: -24px;
    }
  }
}

.account-layout__bracket {
  @include visually-hidden;
}

.account-layout__unread-message {
  @include font('sans-serif',
  'bold');
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  left: 17px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  color: $white;
  background-color: $color-secondary;
  .account-layout__link--active & {
    color: $color-secondary;
    background-color: $white;
  }
}

.account-layout__label {
  display: none;
  margin-left: 18px;
}

.account-layout__main {
  width: 100%;
}

.account-layout__footer {
  padding: 20px 15px;
}

.account-layout__footer-link {
  font-size: 11px;
  color: $grey;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $grey-dark;
  }
}

@media (min-width: 800px) {
  .account-layout {
    display: flex;
    position: relative;
    min-height: 100vh;
    padding-left: 84px;
  }
  .account-layout__nav {
    padding: 0;
    background-color: transparent;
    &::after {
      content: '';
      position: fixed;
      z-index: 1;
      left: 0;
      width: 244px;
      height: 100vh;
      box-shadow: 0 0 10px 0 $shadow;
      transform: translate(-160px);
      background-color: $red;
      transition: transform 0.4s ease-in-out;
    }
  }
  .account-layout__nav--active {
    &::after {
      transform: translate(0);
    }
  }
  .account-layout__nav-list {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: 2;
    top: 30px;
    bottom: 30px;
    left: 30px;
    min-width: 35px;
  }
  .account-layout__nav-item {
    display: block;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 33px;
    }
  }
  .account-layout__link--inbox {
    margin-left: 0;
    padding-top: 0;
  }
  .account-layout__label {
    opacity: 0;
    display: none;
    margin-left: 18px;
    border-bottom: 1px solid transparent;
    animation: dashboard-navigation 0.3s ease-in-out;
    .account-layout__nav--active & {
      opacity: 1;
      display: block;
      transform: scale(1);
    }
  }
  .account-layout__unread-message {
    top: -10px;
    left: 14px;
  }
  .account-layout__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 20px 20px;
  }
}

@keyframes dashboard-navigation {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
  }
}
