@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.user-data-overview__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 14px;
}

.user-data-overview__content {
  margin-bottom: 35px;
}

.user-data-overview__title {
  @include font('sans-serif', 'book');
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: $color-secondary;
}

.user-data-overview__link {
  @include font('sans-serif', 'book');
  appearance: none;
  outline: 0;
  border: 0;
  text-decoration: underline;
  color: $grey;
  background: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
  &::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 13px;
    background-image: url('~static/images/icon-edit.svg');
    background-repeat: no-repeat;
    background-size: 24px 12px;
  }
  &:focus::after,
  &:hover::after {
    background-position: -12px;
  }
}

.user-data-overview__panel {
  border-radius: 4px;
  padding: 15px;
  background-color: $white;
}

.user-data-overview__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-data-overview__list-item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.user-data-overview__definition-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  font-size: 15px;
}

.user-data-overview__definition-key {
  width: 50%;
  color: $black;
}

.user-data-overview__definition-value {
  overflow: hidden;
  width: 50%;
  margin-left: 0;
  text-align: right;
  text-overflow: ellipsis;
  color: $grey;
}

@media (min-width: 700px) {
  .user-data-overview__panel {
    padding: 25px;
  }
  .user-data-overview__definition-list {
    font-size: 19px;
  }
}
