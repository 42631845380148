@import '@/stylesheets/base/constants';
.agreements-separator__wrapper {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;
  background: $white;
}

.agreements-separator__headline {
  margin-bottom: 10px;
  font-size: 16px;
  color: $blue;
}

@media (min-width: 800px) {
  .agreements-separator__wrapper {
    padding: 30px 30px 10px;
  }
}

.agreements-separator+.agreements-separator {
  margin-top: 40px;
}
