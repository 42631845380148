@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.inbox-detail {
  width: 100%;
  padding: 15px 15px 30px;
}

.inbox-detail--hidden-mobile {
  display: none;
}

.inbox-detail__back-button {
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none;
  color: $grey;
  &::before {
    @include font('sans-serif', 'bold');
    content: '←';
    display: inline-block;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    border: 2px solid;
    border-radius: 50%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.inbox-detail__message-title {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 28px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: $color-secondary;
}

.inbox-detail__message-date {
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: $black;
}

.inbox-detail__message-text {
  font-size: 16px;
  line-height: 25px;
}

.inbox-detail__attachment {
  border-top: 1px solid $grey;
  padding-top: 20px;
}

.inbox-detail__attachment-link {
  color: $color-primary;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
}

.inbox-detail__choose-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  text-align: center;
}

.inbox-detail__choose-message-image {
  max-width: 300px;
  margin-bottom: 40px;
}

.inbox-detail__choose-message-text {
  max-width: 500px;
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 19px;
  line-height: 30px;
  color: $black;
}

@media (min-width: 800px) {
  .inbox-detail {
    width: 65%;
    padding-top: 90px;
    padding-right: 50px;
    padding-left: 50px;
  }
  .inbox-detail--hidden-mobile {
    display: flex;
  }
  .inbox-detail__back-button {
    display: none;
  }
  .inbox-detail__attachment {
    padding-top: 40px;
  }
}

@media (min-width: 950px) {
  .inbox-detail {
    padding-top: 100px;
  }
  .inbox-detail__message-date {
    font-size: 19px;
  }
  .inbox-detail__message-text {
    font-size: 19px;
    line-height: 30px;
  }
}

@media (min-width: 1160px) {
  .inbox-detail {
    padding-right: 100px;
    padding-left: 100px;
  }
}
