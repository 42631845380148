@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
@import '@/stylesheets/base/accessibility.scss';
.progress-steps {
  @include font('sans-serif', 'medium');
  position: relative;
  width: 60%;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}

.progress-steps__list {
  display: inline-flex;
  align-items: center;
  list-style: none;
  counter-reset: step;
  margin: 0;
  padding: 0 15px;
}

.progress-steps__list-item {
  display: flex;
  align-items: baseline;
  text-align: left;
  &::before {
    content: counter(step);
    display: block;
    flex-shrink: 0;
    counter-increment: step;
    position: relative;
    z-index: 1;
    width: 32px;
    height: 32px;
    border: 3px solid $white;
    border-radius: 50%;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: $white;
    background-color: $color-primary;
  }
  &:not(:last-child)::after {
    content: '›';
    display: block;
    align-self: center;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 30px;
    text-align: right;
    color: $rose;
  }
  &:last-child {
    justify-content: flex-end;
  }
}

.progress-steps__list-item--active {
  color: $white;
  &::before {
    border-color: $white;
    color: $color-primary;
    background-color: $white;
  }
}

.progress-steps__label {
  @include visually-hidden;
}

@media screen and (min-width: 800px) {
  .progress-steps {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $rose;
    }
  }
  .progress-steps__list {
    justify-content: space-between;
    height: 90px;
    padding: 0 30px;
  }
  .progress-steps__list-item {
    width: 30%;
    &:not(:last-child)::after {
      width: 46px;
      padding-right: 0;
      padding-left: 0;
    }
    &:last-child {
      width: 10%;
    }
  }
}

@media screen and (min-width: 900px) {
  .progress-steps__list {
    justify-content: flex-start;
  }
  .progress-steps__list-item {
    width: auto;
    &:not(:last-child)::after {
      text-align: center;
    }
    &:last-child {
      width: auto;
    }
  }
  .progress-steps__label--active {
    clip-path: none;
    position: relative;
    width: auto;
    height: auto;
    margin: auto;
    margin-left: 15px;
    white-space: nowrap;
  }
}

@media screen and (min-width: 950px) {
  .progress-steps__list {
    font-size: 20px;
  }
}
