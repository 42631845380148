@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.welcome-text {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
}

.welcome-text__wrapper {
  width: 100%;
  max-width: 550px;
}

.welcome-text__headline {
  width: 10em;
  font-size: 28px;
  line-height: 30px;
  color: $white;
}

.welcome-text__text {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: $white;
}

.welcome-text__image-list {
  display: flex;
  flex-direction: row;
  max-width: 550px;
  margin-top: 30px;
}

.welcome-text__image {
  display: flex;
  align-self: flex-start;
  width: 32%;
  height: auto;
  margin-top: 0;
  margin-left: -20%;
}

.welcome-text__list {
  counter-reset: steps;
  width: 62%;
  margin: 0;
  padding-right: 0;
  padding-bottom: 4%;
  padding-left: 20px;
  color: $white;
}

.welcome-text__list-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  &::before {
    @include font('sans-serif', 'bold');
    content: counter(steps);
    display: inline-block;
    flex-shrink: 0;
    counter-increment: steps;
    width: 32px;
    height: 31px;
    margin-right: 16px;
    border-radius: 50%;
    padding-top: 5px;
    padding-right: 1px;
    box-shadow: inset 0 0 0 2px $white;
    text-align: center;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.welcome-text__list-item--active {
  &::before {
    @include font('sans-serif', 'bold');
    content: counter(steps);
    display: inline-block;
    flex-shrink: 0;
    counter-increment: steps;
    vertical-align: middle;
    width: 32px;
    height: 31px;
    margin-right: 16px;
    border-radius: 50%;
    padding-top: 5px;
    padding-right: 1px;
    text-align: center;
    color: $color-primary;
    background-color: $white;
  }
}

@media (min-width: 800px) {
  .welcome-text__headline {
    font-size: 40px;
    line-height: 48px;
  }
  .welcome-text__text {
    font-size: 20px;
    line-height: 28px;
  }
  .welcome-text__image-list {
    margin-top: 60px;
  }
  .welcome-text__image {
    margin-left: 0;
  }
  .welcome-text__list {
    padding-top: 10%;
  }
  .welcome-text__list-item {
    font-size: 20px;
    line-height: 24px;
  }
}
