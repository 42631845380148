@import '@/stylesheets/base/constants';
.inbox-list {
  width: 100%;
  padding-top: 15px;
  background-color: $white;
}

.inbox-list--hidden-mobile {
  display: none;
}

.inbox-list__session {
  padding-top: 5px;
  padding-left: 15px;
}

.inbox-list__content {
  padding-top: 20px;
}

.inbox-list__title {
  margin-top: 0;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 28px;
  color: $color-secondary;
}

.inbox-list__messages {
  padding: 0;
}

.inbox-list__message {
  display: block;
  position: relative;
  border-bottom: 1px solid $grey-light;
  border-left: 7px solid transparent;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: $grey;
  transition: background-color 0.1s;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    background-color: $grey-light;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.inbox-list__message--unread {
  color: $black;
  &::before {
    background-color: $color-secondary;
  }
}

.inbox-list__message--active,
.inbox-list__message--unread {
  color: $black;
}

.inbox-list__message--active {
  border-left: 7px solid $color-secondary;
  background-color: $grey-light;
}

.inbox-list__date {
  display: block;
  margin-top: 5px;
  color: $grey;
}

.inbox-list__no-messages-text {
  padding-right: 15px;
  padding-bottom: 30px;
  padding-left: 15px;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 800px) {
  .inbox-list {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    width: 35%;
    padding-top: 0;
  }
  .inbox-list--hidden-mobile {
    display: block;
  }
  .inbox-list__session {
    padding-top: 25px;
    padding-left: 27px;
  }
  .inbox-list__content {
    position: absolute;
    height: 100%;
    padding-top: 45px;
  }
  .inbox-list__title {
    padding-right: 35px;
    padding-left: 27px;
  }
}

@media (min-width: 950px) {
  .inbox-list__title {
    margin-bottom: 30px;
    padding-left: 42px;
    font-size: 40px;
  }
  .inbox-list__session {
    padding-left: 42px;
  }
  .inbox-list__message {
    padding: 15px 35px;
    font-size: 19px;
    line-height: 25px;
    &::before {
      top: 22px;
      left: 15px;
    }
  }
  .inbox-list__no-messages-text {
    padding: 15px 35px;
    font-size: 19px;
    line-height: 25px;
  }
}
