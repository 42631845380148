@import '@/stylesheets/base/constants';

.input-context {
  margin-bottom: 19px;
}

.input-context__hint {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
  color: $grey;
}

.input-context__error {
  margin-top: 8px;
  font-size: 13px;
  color: $red;
}

@media (min-width: 800px) {
  .input-context {
    margin-bottom: 28px;
  }

  .input-context__hint {
    margin-right: 30%;
  }
}
