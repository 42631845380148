@import '@/stylesheets/base/constants';
.change-bank-info__title {
  color: $color-secondary;
}

.change-bank-info__text {
  line-height: 1.5;
  color: $black;
}

.change-bank-info__link {
  color: $color-primary;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
}
