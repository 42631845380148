@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.button {
  @include font('sans-serif', 'medium');
  display: block;
  width: 100%;
  min-height: 48px;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 10px 30px 8px;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: $white;
  background-color: $color-secondary;
  cursor: pointer;
  &:focus,
  &:hover,
  &.button--outlined {
    outline: 0;
    border-color: $color-secondary;
    color: $color-secondary;
    background-color: $white;
  }
  &.button--outlined:hover {
    color: $white;
    background-color: $color-secondary;
  }
  &.button--outlined:focus {
    border: 3px solid $color-secondary;
  }
  &:disabled {
    border-color: $grey;
    color: $white;
    background-color: $grey;
    cursor: not-allowed;
  }
  &.button--plain {
    @include font('sans-serif', 'book');
    display: block;
    height: auto;
    border: none;
    padding: 0;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
    color: $color-primary;
    background-color: transparent;
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
}

@media screen and (min-width: 800px) {
  .button {
    display: inline-block;
    width: inherit;
    height: 56px;
    font-size: 20px;
  }
}
