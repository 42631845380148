@import '@/stylesheets/base/constants';
.dashboard-graph {
  position: relative;
  margin-bottom: 35px;
}

.dashboard-graph__empty-chart {
  position: absolute;
  left: 50%;
  width: 50%;
  height: 100%;
  transform: translate(-50%);
  text-align: center;
}

.dashboard-graph__image {
  height: 70%;
}

.dashboard-graph__placeholder-text {
  display: none;
  color: $blue;
}

@media (min-width: 620px) {
  .dashboard-graph__placeholder-text {
    display: inherit;
  }
}

@media (min-width: 950px) {
  .dashboard-graph {
    margin-bottom: 70px;
  }
  .dashboard-graph__image {
    height: 50%;
  }
}

@media (min-width: 1284px) {
  .dashboard-graph__image {
    height: 70%;
  }
}
