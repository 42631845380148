@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.status {
  width: 100%;
}

.status__headline {
  @include font('sans-serif', 'bold');
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  color: $color-secondary;
}

.status__text {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}

@media (min-width: 800px) {
  .status__headline {
    margin-bottom: 20px;
    font-size: 40px;
  }
  .status__text {
    margin-bottom: 40px;
    font-size: 19px;
  }
}
