@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.data-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.data-table__head-cell {
  text-align: left;
}

.data-table__head-cell,
.data-table__body-cell {
  border-bottom: 1px solid $grey;
  padding: 20px;
  &:first-child {
    padding-left: 40px;
  }
}

.data-table__body-cell {
  position: relative;
}

.data-table__body-cell--unobtrusive {
  color: $grey;
}

.data-table__body-cell--unread {
  @include font('sans-serif', 'bold');
  color: $color-secondary;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15px;
    width: 12px;
    height: 12px;
    margin-top: -1px;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: $color-secondary;
  }
}
