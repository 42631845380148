@import '@/stylesheets/base/constants';
.front-form-layout {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100vh;
  background-color: $grey-light;
}

.front-form-layout__top {
  flex-shrink: 0;
  height: 80px;
  padding: 0 2vw;
}

.front-form-layout__content {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.front-form-layout__footer {
  flex-shrink: 0;
  padding: 15px 15px 20px;
  text-align: left;
}

.front-form-layout__footer-link {
  font-size: 11px;
  color: $grey;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $grey-dark;
  }
}

@media (min-width: 800px) {
  .front-form-layout__content {
    width: 440px;
    margin: 0 auto;
  }
  .front-form-layout__footer {
    padding: 20px;
    text-align: right;
  }
}
