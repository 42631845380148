@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.dashboard-masthead {
  margin-bottom: 35px;
}

.dashboard-masthead__amount {
  flex-grow: 1;
  margin-bottom: 20px;
}

.dashboard-masthead__balance {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 40px;
  line-height: 40px;
  color: $color-secondary;
}

.dashboard-masthead__performance {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: $grey;
}

.dashboard-masthead__performance-title {
  @include font('sans-serif', 'medium');
  display: inline;
}

.dashboard-masthead__performance-values {
  display: inline;
  margin-left: 0;
  color: $color-secondary;
}

.dashboard-masthead__button-group {
  display: flex;
}

.dashboard-masthead__button {
  width: 50%;
  &:not(:last-child) {
    padding-right: 3%;
  }
}

@media (min-width: 950px) {
  .dashboard-masthead__balance {
    font-size: 50px;
    line-height: 50px;
  }
}

@media (min-width: 1020px) {
  .dashboard-masthead__button {
    width: inherit;
    &:not(:last-child) {
      padding-right: 20px;
    }
  }
}

@media (min-width: 1450px) {
  .dashboard-masthead {
    display: flex;
  }
  .dashboard-masthead__amount {
    margin-bottom: 0;
  }
  .dashboard-masthead__button-group {
    padding-left: 20px;
  }
}
