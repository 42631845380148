/* Sans-serif */

@font-face {
  font-family: 'Retina';
  font-weight: 400;
  src: url('~static/fonts/Retina-Book.woff') format('woff');
}

@font-face {
  font-family: 'Retina';
  font-weight: 500;
  src: url('~static/fonts/Retina-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Retina';
  font-weight: 700;
  src: url('~static/fonts/Retina-Bold.woff') format('woff');
}


/* Serif */

@font-face {
  font-family: 'Exchange';
  font-weight: 400;
  src: url('~static/fonts/Exchange-Book.woff') format('woff');
}

@font-face {
  font-family: 'Exchange';
  font-weight: 700;
  src: url('~static/fonts/Exchange-Bold.woff') format('woff');
}


/* Script */

@font-face {
  font-family: 'Manus';
  font-weight: 400;
  src: url('~static/fonts/Manus.woff') format('woff');
}
