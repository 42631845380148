@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
@import '@/stylesheets/base/accessibility.scss';
.info-message {
  @include font('sans-serif', 'medium');
  position: fixed;
  z-index: 3;
  right: 3%;
  bottom: 20px;
  left: 3%;
  border: 1px solid $color-secondary;
  padding: 12px 12px 10px;
  box-shadow: 0 0 4px 0 $shadow;
  font-size: 16px;
  line-height: 1.2;
  background-color: $white;
}

.info-message__inner {
  display: flex;
  align-items: center;
}

.info-message--positive {
  color: $green;
}

.info-message--negative {
  color: $red;
}

.info-message--hidden {
  display: none;
}

.info-message__text {
  padding-right: 20px;
}

.info-message__close-button {
  display: inline-block;
  min-width: 17px;
  height: 17px;
  margin-left: auto;
  border: 0;
  padding-right: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 34px 17px;
  cursor: pointer;
  &:focus,
  &:active,
  &:hover {
    background-position: -17px;
  }
  .info-message--positive & {
    background-image: url('~static/images/icon-close-green.svg');
  }
  .info-message--negative & {
    background-image: url('~static/images/icon-close-red.svg');
  }
}

.info-message__close-text {
  @include visually-hidden;
}

@media (min-width: 750px) {
  .info-message {
    padding: 19px 19px 16px;
    font-size: 19px;
  }
}
