@import '@/stylesheets/base/constants';
.dashboard-info-message {
  display: none;
}

.dashboard-info-message__button-link {
  margin-left: auto;
  border: 2px solid transparent;
  border-radius: 3px;
  padding: 7px 26px 6px;
  font-size: 18px;
  text-decoration: none;
  color: $color-secondary;
  background-color: $white;
  &:focus {
    outline: 0;
  }
  &:focus,
  &:hover {
    border: 2px solid $white;
    color: $white;
    background-color: transparent;
  }
}

@media (min-width: 800px) and (min-height: 500px) {
  .dashboard-info-message {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 25px;
    font-size: 18px;
    color: $white;
    background-color: $color-secondary;
    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 18px;
      margin-top: -3px;
      margin-right: 25px;
      background-image: url('~static/images/icon-envelope.svg');
      background-repeat: no-repeat;
      background-size: 48px;
    }
  }
}
