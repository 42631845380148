@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
$main-vertical-space: 80px;

/* helps centering the content */

.onboarding-layout {
  display: flex;
  flex-direction: column;
}

.onboarding-layout-wrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 100vh;
}

.onboarding-layout__aside {
  padding-bottom: 20px;
  background-color: $color-primary;
}

.onboarding-layout__main {
  flex-direction: column;
  /* justify-content: center; */
  flex-grow: 1;
  /* align-items: center; */
  background-color: $grey-light;
}

.onboarding-layout__main-header {
  padding: 20px 30px 0;
}

.onboarding-layout__main-content {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  padding: 30px;
}

.onboarding-layout__main-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding: 20px 30px;
}

.onboarding-layout__contact-info {
  margin-bottom: 20px;
  text-align: left;
}

.contact-info__headline {
  @include font('sans-serif', 'book');
  margin: 0 0 10px;
  font-size: 18px;
  color: $blue;
}

.contact-info__link {
  display: block;
  position: relative;
  padding-right: 24px;
  padding-left: 24px;
  font-size: 14px;
  text-decoration: none;
  color: $grey;
  &:first-of-type {
    margin-bottom: 15px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
  }
  &--mail::before {
    top: 3px;
    width: 16px;
    height: 16px;
    background-image: url('~static/images/icon-envelope.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 200%;
  }
  &--phone::before {
    top: 1px;
    width: 16px;
    height: 16px;
    background-image: url('~static/images/phone.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &:active,
  &:focus,
  &:hover {
    color: $grey-dark;
  }
}

.onboarding-layout__footer-link {
  font-size: 11px;
  color: $grey;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $grey-dark;
  }
}

@media (max-width: 799px) {
  .onboarding-layout__footer-link {
    align-self: flex-end;
  }
}

@media (min-width: 500px) {
  .contact-info__headline {
    font-size: 20px;
  }
  .contact-info__link {
    font-size: 16px;
  }
}

@media (min-width: 800px) {
  .onboarding-layout-wrapper {
    flex-direction: row;
  }
  .onboarding-layout__aside,
  .onboarding-layout__main {
    width: 50%;
    max-width: 50%;
  }
  .onboarding-layout__aside {
    display: flex;
    flex-grow: 1;
    padding-bottom: 0;
  }
  .onboarding-layout__main {
    display: flex;
  }
  .onboarding-layout__main-header {
    padding: 28px 4vw 0;
  }
  .onboarding-layout__main-content {
    margin-right: auto;
    padding: $main-vertical-space 4vw;
  }
  .contact-info__link {
    display: inline-block;
  }
  .onboarding-layout__main-footer {
    flex-direction: row;
    align-items: flex-end;
    padding: 0 4vw 40px;
  }
  .onboarding-layout__contact-info {
    flex-direction: row;
    margin-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .contact-info__link:first-of-type {
    margin-bottom: 0;
  }
}
