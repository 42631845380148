@import '@/stylesheets/base/typography.scss';
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include font('sans-serif', 'book');
  font-size: 16px;
}
