@import '@/stylesheets/base/constants';
.deposit-activity {
  padding: 25px 15px;
  background-color: $grey-light;
}

.deposit-activity__headline {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 20px;
  color: $color-secondary;
}

.deposit-activity__pending-transactions {
  color: $grey;
}

.deposit-activity__link {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
  color: $color-primary;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
}

@media (min-width: 800px) {
  .deposit-activity {
    padding: 25px 15px 35px;
  }
  .deposit-activity__headline {
    margin-bottom: 45px;
    font-size: 24px;
    line-height: 24px;
  }
}

@media (min-width: 950px) {
  .deposit-activity {
    min-height: 100vh;
    padding: 115px 20px 50px;
  }
}
