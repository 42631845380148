@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.button-link {
  @include font('sans-serif', 'medium');
  display: block;
  vertical-align: top;
  height: 48px;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 13px 30px 8px;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
  color: $white;
  background-color: $color-secondary;
  cursor: pointer;
  &:focus,
  &:hover,
  &.button-link--outlined {
    outline: 0;
    border-color: $color-secondary;
    color: $color-secondary;
    background-color: $white;
  }
  &.button-link--disabled {
    outline: 0;
    border-color: $grey;
    color: $white;
    background-color: $grey;
    pointer-events: none;
  }
  &.button-link--outlined:hover {
    color: $white;
    background-color: $color-secondary;
  }
  &.button-link--outlined:focus {
    border: 3px solid $color-secondary;
  }
}

.button-link--loading {
  position: relative;
}

.button-link__spinner {
  position: absolute;
  top: 9px;
  left: 15px;
}

@media screen and (min-width: 800px) {
  .button-link {
    display: inline-block;
    height: 56px;
    padding: 16px 30px 8px;
    font-size: 20px;
  }
  .button-link--loading {
    position: relative;
    padding-left: 50px;
  }
  .button-link__spinner {
    position: absolute;
    top: 11px;
  }
}
