@import '@/stylesheets/base/constants';
.ffb-disclaimer {
  display: none;
  padding: 2vw;
  background-color: $white;
}

.ffb-disclaimer__text {
  margin-left: 30px;
  font-size: 14px;
  line-height: 1.7;
  color: $grey;
}

@media screen and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .ffb-disclaimer__image {
    height: 56px;
    transform: translateY(50%);
  }
}

@media (min-width: 800px) {
  .ffb-disclaimer {
    display: flex;
  }
}
