@import '@/stylesheets/base/constants';
.form-footer {
  p {
    font-size: 14px;
    line-height: 1.5;
    color: $grey;
  }
  a {
    color: $color-primary;
    &:focus {
      outline: 0;
    }
    &:active,
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
}

@media (min-width: 800px) {
  .form-footer {
    p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}
