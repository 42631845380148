@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.large-numbered-input__input {
  @include font('sans-serif', 'book');
  display: block;
  width: 180px;
  height: 76px;
  outline: 0;
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 25px 12px 21px;
  font-size: 25px;
  letter-spacing: 12px;
  line-height: 76px;
  &:focus {
    border-color: $blue;
    box-shadow: inset 3px 3px $transparent-black;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}

.large-numbered-input__input--error {
  border-color: $red;
  &+.large-numbered-input__label {
    color: $red;
  }
}
