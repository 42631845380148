@mixin font($classification, $weight) {

  $sans-serif-font-stack: 'Retina', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  $serif-font-stack: 'Exchange', Georgia, 'Times New Roman', serif;
  $script-stack: 'Manus', cursive;

  @if $classification == 'sans-serif' {
    @if $weight == 'book' {
      font-family: $sans-serif-font-stack;
      font-weight: 400;
    } @else if $weight == 'medium' {
      font-family: $sans-serif-font-stack;
      font-weight: 500;
    } @else if $weight == 'bold' {
      font-family: $sans-serif-font-stack;
      font-weight: 700;
    } @else {
      @error 'The sans-serif font does not support the weight `#{$weight}`. Please use "book", "medium" or "bold".';
    }
  }

  @if $classification == 'serif' {
    @if $weight == 'book' {
      font-family: $serif-font-stack;
      font-weight: 400;
    } @else if $weight == 'bold' {
      font-family: $serif-font-stack;
      font-weight: 700;
    } @else {
      @error 'The serif font does not support the weight: `#{$weight}`. Please use "book" or "bold".';
    }
  }

  @if $classification == 'script' {
    @if $weight == 'book' {
      font-family: $script-stack;
      font-weight: 400;
    } @else {
      @error 'The script font does not support the weight: `#{$weight}`. Please use "book".';
    }
  }
}
