.dashboard__main {
  padding: 60px 15px;
}

.dashboard__session {
  position: absolute;
  padding: 20px 15px;
}

@media (min-width: 800px) {
  .dashboard__main {
    padding: 65px 15px;
  }
}

@media (min-width: 950px) {
  .dashboard {
    display: flex;
  }

  .dashboard__session {
    padding: 25px 50px;
  }

  .dashboard__main {
    width: 60%;
    padding-right: 50px;
    padding-left: 50px;
  }

  .dashboard__aside {
    width: 40%;
  }
}
