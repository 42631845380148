@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.not-found__aside {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.not-found__image {
  max-width: 260px;
}

.not-found__headline {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 60px;
  color: $color-secondary;
}

.not-found__subheadline {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38px;
  color: $color-secondary;
}

.not-found__text {
  margin-top: 9px;
  font-size: 19px;
  line-height: 1.5;
}

.not-found__return {
  @include font('sans-serif', 'medium');
  display: inline-block;
  margin-top: 0;
  font-size: 19px;
  color: $color-primary;
}

@media (min-width: 800px) {
  .not-found__headline {
    font-size: 120px;
  }
  .not-found__image {
    width: inherit;
  }
  .not-found__return {
    margin-top: 25px;
  }
}
