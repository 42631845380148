@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.labeled-select {
  position: relative;
}

.labeled-select__label {
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 12px;
  font-size: 13px;
  color: $grey;
  pointer-events: none;
}

.labeled-select__select-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid $grey;
  border-radius: 4px;
  pointer-events: none;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 0;
    height: 0;
    border-top: 7px solid $grey;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: translateY(-50%);
  }
  .labeled-select__select:focus+& {
    border-color: $blue;
    box-shadow: inset 3px 3px $transparent-black;
  }
}

.labeled-select__select-wrapper--error {
  border-color: $red;
}

.labeled-select__select {
  @include font('sans-serif', 'book');
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  padding-top: 15px;
  padding-left: 12px;
  font-size: 17px;
  background-color: $white;
  &:disabled {
    background-color: $grey-light;
  }
  &:-moz-focusring {
    text-shadow: 0 0 0 $black;
    color: transparent;
  }
}

@media (min-width: 800px) {
  .labeled-select__label {
    top: 10px;
  }
  .labeled-select__select-wrapper {
    height: 56px;
  }
  .labeled-select__select {
    padding-top: 20px;
    font-size: 19px;
  }
}
