@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/accessibility.scss';
.labeled-radio__group-label {
  display: block;
  margin-bottom: 10px;
  color: $grey;
}

.labeled-radio__group-field {
  display: flex;
  flex-direction: column;
  .labeled-radio__radio:not(:last-child) {
    margin-bottom: 10px;
  }
}

.labeled-radio__group-field--inline {
  flex-direction: row;
  .labeled-radio__radio:not(:last-child) {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.labeled-radio__radio {
  display: inline-block;
}

.labeled-radio__label {
  display: block;
  position: relative;
  height: 20px;
  padding-left: 30px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    border-radius: 50%;
  }
  &::before {
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $grey;
    background-color: $white;
    .labeled-radio__input:focus+& {
      border-color: $blue;
      box-shadow: inset 3px 3px $transparent-black;
    }
  }
}

.labeled-radio__label--error::before {
  border-color: $red;
}

.labeled-radio__input {
  @include visually-hidden;
  &:checked+.labeled-radio__label::after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    background-color: $color-secondary;
  }
}
