@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.dashboard-saving-plan__masthead {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 25px;
  font-size: 14px;
}

.dashboard-saving-plan__title {
  @include font('sans-serif', 'book');
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: $color-secondary;
}

.dashboard-saving-plan__link {
  @include font('sans-serif', 'book');
  color: $grey;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
  &::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 13px;
    background-image: url('~static/images/icon-edit.svg');
    background-repeat: no-repeat;
    background-size: 24px 12px;
  }
  &:focus::after,
  &:hover::after {
    background-position: -12px;
  }
}

.dashboard-saving-plan__definition {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 10px;
  font-size: 19px;
  &:last-child {
    margin-bottom: 0;
  }
}

.dashboard-saving-plan__definition-key {
  width: 60%;
  font-size: 14px;
  color: $black;
}

.dashboard-saving-plan__definition-value {
  overflow: hidden;
  width: 40%;
  margin-left: 0;
  font-size: 14px;
  text-align: right;
  text-overflow: ellipsis;
  color: $grey;
}

@media (min-width: 1130px) {
  .dashboard-saving-plan {
    width: 50%;
  }
  .dashboard-saving-plan__masthead {
    margin-bottom: 50px;
  }
  .dashboard-saving-plan__definition-key {
    font-size: 19px;
  }
  .dashboard-saving-plan__definition-value {
    font-size: 19px;
  }
}
