@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.session-timer {
  @include font('sans-serif', 'book');
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  color: $color-secondary;
}

.session-timer__image {
  vertical-align: middle;
  width: 14px;
  margin-right: 10px;
  padding-bottom: 2px;
}

.session-timer__count {
  @include font('sans-serif', 'bold');
}
