@import '@/stylesheets/base/constants';
.chart-keys {
  display: flex;
  list-style: none;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 0;
}

.chart-keys__item {
  &:not(:last-child) {
    margin-right: 30px;
  }
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-right: 13px;
    margin-bottom: 3px;
    border-radius: 50%;
  }
}

.chart-keys__item--light-blue {
  &::before {
    background-color: $blue-light;
  }
}

.chart-keys__item--blue {
  &::before {
    background-color: $blue;
  }
}

.chart-keys__item--grey {
  &::before {
    background-color: $grey;
  }
}

.chart-keys__definition-list {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
}

.chart-keys__definition-key {
  font-size: 12px;
  line-height: 1.3;
}

.chart-keys__definition-value {
  margin-left: 0;
  font-size: 12px;
  line-height: 1.2;
  color: $grey;
}

@media (min-width: 800px) {
  .chart-keys__item {
    &::before {
      width: 20px;
      height: 20px;
    }
  }
  .chart-keys__definition-key {
    font-size: 16px;
  }
  .chart-keys__definition-value {
    font-size: 14px;
    line-height: 1.5;
  }
}
