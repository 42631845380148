@import '@/stylesheets/base/constants';
.dashboard-form-layout {
  height: 100vh;
  padding: 15px 15px 30px;
  background-color: $grey-light;
}

.dashboard-form-layout__session {
  padding: 5px 0 20px;
}

@media (min-width: 800px) {
  .dashboard-form-layout {
    padding-top: 115px;
    padding-left: 115px;
  }
  .dashboard-form-layout__session {
    position: absolute;
    top: 0;
    padding: 25px 0 30px;
  }
  .dashboard-form-layout__content {
    max-width: 450px;
  }
}
