@import '@/stylesheets/base/constants';
.order-history {
  min-height: 100vh;
  background-color: $grey-light;
}

.order-history__session {
  padding: 20px 15px 0;
}

.order-history__layout {
  max-width: 800px;
  padding: 26px 15px;
}

.order-history__headline {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 20px;
  color: $color-secondary;
}

@media (min-width: 800px) {
  .order-history__layout {
    padding: 65px 15px;
  }
}

@media (min-width: 950px) {
  .order-history__layout {
    min-height: 100vh;
    padding: 65px 50px 50px;
  }
  .order-history__session {
    padding: 25px 50px 0;
  }
  .order-history__headline {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 40px;
  }
}
