@import '@/stylesheets/base/constants';
.privacy-policy {
  table {
    border-spacing: 0;
    text-align: left;
  }
  thead {
    background: $grey-light;
  }
  td,
  th {
    border-right: 1px solid $grey;
    padding: 5px;
  }
  a {
    color: $color-primary;
    &:focus {
      outline: 0;
    }
    &:active,
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
}
