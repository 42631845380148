@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.logout-button {
  @include font('sans-serif', 'medium');
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  outline: 0;
  border: 2px solid currentColor;
  border-radius: 4px;
  padding: 7px 16px 6px;
  font-size: 16px;
  text-align: center;
  color: $white;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
}

@media (min-width: 800px) {
  .logout-button {
    display: inline-block;
    padding: 7px 26px 6px;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: $color-secondary;
    &:hover,
    &:focus {
      border-color: $color-secondary;
      color: $white;
      background-color: $color-secondary;
    }
  }
}
