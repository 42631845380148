@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.help-text__content {
  @include font('script', 'book');
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 24px;
  color: $white;
}

@media screen and (min-width: 800px) {
  .help-text {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    align-items: center;
  }
  .help-text__content {
    margin-bottom: 0;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 36px;
  }
  .help-text__content--arrow-top {
    &::before {
      content: '';
      display: block;
      width: 245px;
      height: 105px;
      margin-bottom: -10px;
      margin-left: auto;
      background-image: url('~static/images/pencil-arrow-top-right.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  .help-text__content--arrow-bottom {
    &::after {
      content: '';
      display: block;
      width: 245px;
      height: 105px;
      margin-top: 10px;
      margin-left: auto;
      background-image: url('~static/images/pencil-arrow-bottom-right.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
}

@media screen and (min-width: 950px) {
  .help-text__content {
    margin-right: 6vw;
  }
  .help-text__content--arrow-top {
    &::before {
      margin-right: -80px;
    }
  }
  .help-text__content--arrow-bottom {
    &::after {
      margin-right: -80px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .help-text__content {
    max-width: 500px;
    margin-right: 6vw;
    margin-left: auto;
    padding-left: 0;
  }
}

@media screen and (min-width: 1200px) and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .help-text__content {
    margin-right: 0;
    margin-left: 0;
  }
}
