@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
@import '@/stylesheets/base/accessibility.scss';
.progress-status {
  @include font('sans-serif', 'medium');
  position: relative;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}

.progress-status__list {
  display: inline-flex;
  align-items: center;
  counter-reset: step;
  margin: 0;
  padding: 0 15px;
}

.progress-status__list-item {
  list-style: none;
  font-size: 20px;
  &::before {
    content: counter(step);
    display: block;
    counter-increment: step;
    width: 32px;
    height: 32px;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 0 3px $color-primary;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: $color-primary;
    background-color: $white;
  }
}

.progress-status__title {
  display: none;
  color: $white;
}

.progress-status__label {
  @include visually-hidden;
}

@media screen and (min-width: 800px) {
  .progress-status {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 20px;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $rose;
    }
  }
  .progress-status__list {
    height: 90px;
    padding: 0 18px 0 30px;
  }
  .progress-status__title {
    display: inline-block;
  }
}
