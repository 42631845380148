@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.cookiebar {
  display: none;
  overflow: visible;
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $grey-light;
  padding: 0;
  line-height: 16px;
  background-color: $white;
}

.cookiebar__inner {
  max-width: 1440px;
  margin: 0 auto;
  padding: 15px;
}

.cookiebar__text {
  margin-bottom: 15px;
  font-size: 12px;
  color: $grey;
}

.cookiebar__close {
  @include font('sans-serif', 'medium');
  display: block;
  width: 100%;
  min-height: 48px;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 10px 30px 8px;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: $white;
  background-color: $color-secondary;
  cursor: pointer;
  &:focus,
  &:hover {
    outline: 0;
    border-color: $color-secondary;
    color: $color-secondary;
    background-color: $white;
  }
}

.cookiebar__link {
  border-bottom: 1px solid $red;
  text-decoration: none;
  color: $grey;
  transition: color 0.3s ease;
  &:hover {
    color: $red;
  }
}

.cookiebar--visible {
  display: block;
}

@media screen and (min-width: 800px) {
  .cookiebar__inner {
    display: flex;
    padding: 22px 25px;
  }
  .cookiebar__text {
    width: 80%;
    margin-bottom: 0;
    padding-right: 30px;
  }
  .cookiebar__close {
    width: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .cookiebar__text {
    padding-right: 80px;
    font-size: 16px;
    line-height: 20px;
  }
  .cookiebar__close {
    font-size: 19px;
    line-height: 24px;
  }
}
