.logo {
  padding: 20px 18px 19px;
}

.logo__content {
  width: 180px;
}

@media (min-width: 800px) {
  .logo {
    position: absolute;
    top: 18px;
    right: 15px;
  }

  .logo__content {
    width: auto;
  }
}
