@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.back-button {
  display: block;
}

.back-button__button {
  @include font('sans-serif', 'book');
  margin-top: 25px;
  border: 0;
  font-size: 16px;
  line-height: 1.5;
  color: $grey;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $grey-dark;
  }
}

@media (min-width: 800px) {
  .back-button__button {
    margin-top: 40px;
    font-size: 19px;
  }
}
