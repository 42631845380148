/* Colors */

$red: #de1f37;
$rose: #ea6d7d;
$blue: #132066;
$blue-light: #989fbf;
$green: #09ad7e;
$grey: #9b9b9b;
$grey-light: #f6f6f6;
$grey-dark: #666;
$white: #fff;
$black: #000;

$transparent-white: rgba($white, 0.5);
$transparent-black: rgba($black, 0.15);
$shadow: rgba($black, 0.5);

$color-primary: $red;
$color-secondary: $blue;
