@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.agreements-toggle {
  margin-bottom: 20px;
  margin-left: 0;
  padding-bottom: 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $transparent-black;
  }
}

.agreements-toggle__button {
  @include font('sans-serif', 'book');
  display: inline-block;
  margin-right: 10px;
  border: 0;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: underline;
  color: $color-primary;
  background-color: transparent;
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
    .agreements-toggle__button-image-path {
      stroke: $color-secondary;
    }
  }
  &:focus,
  &:hover {
    color: $color-secondary;
    .agreements-toggle__button-image-path {
      stroke: $color-secondary;
    }
  }
}

.agreements-toggle__button-image {
  margin-right: 10px;
}

.agreements-toggle--active {
  .agreements-toggle__button-image {
    transform: rotate(180deg);
  }
}

.agreements-toggle__content {
  display: none;
  overflow-y: scroll;
  max-height: 300px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  &[aria-hidden='false'] {
    display: block;
  }
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }
  h4 {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
  }
  h5 {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
  }
  p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 1.5;
    color: $black;
  }
  a {
    color: $color-primary;
    &:focus {
      outline: 0;
    }
    &:active,
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
}

@media (min-width: 500px) {
  .agreements-toggle {
    margin-left: 30px;
  }
  .agreements-toggle__content {
    padding-right: 10px;
    padding-left: 0;
    ul {
      padding-left: 30px;
    }
  }
}

@media (min-width: 800px) {
  .agreements-toggle__content {
    p {
      font-size: 16px;
      line-height: 1.4;
    }
    h5 {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}
