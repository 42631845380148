@import '@/stylesheets/base/constants';
.loader {
  opacity: 1;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  transform-origin: left;
  background-color: $blue;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.loader--start {
  transform: scaleX(0.95);
  transition: transform 10s ease-out;
}

.loader--stop {
  opacity: 0;
  transform: scaleX(1);
  transition: transform 0.5s ease-in, opacity 0.7s ease-out;
}
