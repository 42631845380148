@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.chart-description {
  position: relative;
  margin-top: 20px;
  margin-bottom: 35px;
  padding-left: 30px;
}

.chart-description__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url('~static/images/icon-question.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.chart-description__text {
  margin-top: 0;
  margin-bottom: 10;
  font-size: 14px;
  line-height: 1.5;
  color: $grey;
  &--underline {
    @include font('sans-serif',
    'bold');
    text-decoration: underline;
  }
  &+.chart-description__text:last-child {
    margin-bottom: 0;
  }
  a {
    color: $color-primary;
    &:focus {
      outline: 0;
    }
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
}

@media (min-width: 800px) {
  .chart-description {
    padding-right: 30%;
  }
}
