@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.faq-list {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 15px;
  padding-left: 15px;
}

.faq-list__items {
  display: none;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  .faq-list--active & {
    display: block;
  }
}

.faq-list__button {
  @include font('sans-serif', 'book');
  position: relative;
  width: 100%;
  height: 48px;
  outline: 0;
  border: 1px solid $white;
  border-radius: 3px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 16px;
  text-align: left;
  color: $white;
  background: none;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 0;
    height: 0;
    border-top: 7px solid $white;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: translateY(-50%);
  }
  .faq-list--active & {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.faq-list__question {
  border-bottom: 1px solid $transparent-black;
  padding: 15px 0;
}

.faq-list__link {
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  color: $white;
}

.faq-list__label {
  width: 96%;
  margin-top: 2px;
  padding-right: 15px;
}

@media screen and (min-width: 800px) {
  .faq-list {
    margin-bottom: 100px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .faq-list__items {
    display: block;
  }
  .faq-list__button {
    display: none;
  }
  .faq-list__link {
    font-size: 19px;
  }
}

@media screen and (min-width: 1200px) {
  .faq-list {
    width: 100%;
    max-width: 500px;
    margin-right: 6vw;
    margin-left: auto;
    padding-left: 0;
  }
}
