@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.confirm-identity__headline {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 40px;
  color: $color-secondary;
}

.confirm-identity__text {
  margin-top: 20px;
  font-size: 19px;
  line-height: 1.5;
}

.confirm-identity__methods {
  margin-top: 40px;
  margin-bottom: 45px;
}

.confirm-identity__panel {
  position: relative;
  padding: 25px 30px 15px;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  color: $grey;
  background-color: $white;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.confirm-identity__recommendation {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 8px 4px;
  font-size: 14px;
  color: $white;
  background-color: $color-secondary;
}

.confirm-identity__image-post {
  max-width: 105px;
}

.confirm-identity__image-hand {
  max-width: 80px;
}

.confirm-identity__title {
  margin-bottom: 25px;
  font-size: 20px;
  text-align: center;
  color: $color-secondary;
}

.confirm-identity__list {
  padding-left: 0;
}

.confirm-identity__list-item {
  @include font('sans-serif', 'book');
  display: block;
  list-style: none;
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
  text-align: left;
  &::before {
    content: '✓';
    position: absolute;
    top: 0;
    right: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    border-radius: 50%;
    text-align: center;
    color: $white;
    background-color: $color-primary;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.confirm-identity__time-out {
  color: $red;
}

@media (min-width: 1200px) {
  .confirm-identity__methods {
    display: flex;
    align-items: flex-end;
  }
  .confirm-identity__panel {
    width: 50%;
    margin-right: 25px;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
