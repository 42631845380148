@import '@/stylesheets/base/typography.scss';
.arrow {
  @include font('sans-serif', 'bold');
  display: inline-block;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  border: 2px solid;
  border-radius: 50%;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
}
