@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.deposit-history__list {
  padding-left: 0;
}

.deposit-history__list-item {
  display: flex;
  padding: 20px 12px 12px;
  background-color: $white;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.deposit-history__list-item--buy {
  color: $color-secondary;
}

.deposit-history__list-item--sell {
  color: $grey;
}

.deposit-history__column {
  margin-top: 0;
  margin-bottom: 0;
  &:first-child {
    min-width: 40%;
  }
  &:last-child {
    min-width: 60%;
  }
}

.deposit-history__date {
  display: block;
  list-style: none;
  padding-left: 0;
  font-size: 10px;
  letter-spacing: 0.5px;
  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 20px;
    margin-top: 12px;
  }
}

.deposit-history__date--listed::after {
  margin-top: 8px;
}

.deposit-history__date::after {
  .deposit-history__list-item--buy & {
    background: url('~static/images/icon-upload.svg');
  }
  .deposit-history__list-item--sell & {
    background: url('~static/images/icon-download.svg');
  }
}

.deposit-history__type {
  margin-bottom: 7px;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-align: right;
  text-transform: uppercase;
}

.deposit-history__amount {
  @include font('sans-serif',
  'medium');
  font-size: 24px;
  text-align: right;
}

@media (min-width: 800px) {
  .deposit-history__list-item {
    padding: 20px 20px 12px;
  }
  .deposit-history__amount {
    font-size: 28px;
  }
  .deposit-history__column {
    &:first-child {
      min-width: 30%;
    }
    &:last-child {
      min-width: 70%;
    }
  }
}
