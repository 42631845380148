@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.task-list {
  margin-top: 20px;
}

.task-list__headline {
  @include font('sans-serif', 'medium');
  font-size: 16px;
  color: $color-secondary;
}

.task-list__list {
  margin-bottom: 35px;
  padding-left: 0;
}

.task-list__task {
  @include font('sans-serif', 'book');
  display: block;
  position: relative;
  margin-bottom: 18px;
  padding-left: 45px;
  font-size: 16px;
  line-height: 1.8;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    line-height: 1.7;
    text-align: center;
  }
}

.task-list__task--done {
  color: $black;
  &::before {
    content: '';
    border: 2px solid $color-secondary;
    background: transparent url('~static/images/thin-checkmark-blue.svg') no-repeat center center;
  }
}

.task-list__task--undone {
  &::before {
    content: '\00a0';
    border: 2px solid $color-primary;
  }
}

.task-list__link {
  color: $color-primary;
}

@media (min-width: 800px) {
  .task-list {
    margin-top: 40px;
  }
  .task-list__headline {
    font-size: 20px;
  }
  .task-list__list {
    margin-top: 30px;
  }
  .task-list__task {
    padding-left: 50px;
    font-size: 19px;
    line-height: 1.5;
    &::before {
      line-height: 1.5;
    }
  }
  @media screen and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    .task-list__task::before {
      line-height: 1.3;
    }
  }
}
