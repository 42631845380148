@import '@/stylesheets/base/constants';
.country-not-available__image {
  display: block;
  max-width: 300px;
  margin-bottom: 30px;
}

.country-not-available__headline {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 40px;
  color: $color-secondary;
}

.country-not-available__text {
  margin-top: 20px;
  font-size: 19px;
  line-height: 1.5;
}

@media (min-width: 800px) {
  .country-not-available__image {
    margin-left: 30%;
  }
}
