@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.legal-agreements-form__title {
  @include font('sans-serif', 'medium');
  display: block;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 1.1;
  color: $color-secondary;
}

.legal-agreements-form__list {
  li {
    margin-bottom: 10px;
    line-height: 18px;
  }
}
