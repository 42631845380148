@media (min-width: 1130px) {
  .side-by-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .side-by-side__box {
    .side-by-side--half & {
      flex-grow: 0;
      flex-shrink: 0;
      width: calc(50% - 14px);
    }

    .side-by-side--golden &:nth-child(1) {
      flex-grow: 0;
      flex-shrink: 0;
      width: calc(61.8% - 14px);
    }

    .side-by-side--golden &:nth-child(2) {
      flex-grow: 0;
      flex-shrink: 0;
      width: calc(38.2% - 14px);
    }
  }
}
