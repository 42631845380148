@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.labeled-checkbox__checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.labeled-checkbox__label {
  display: inline-block;
  position: relative;
  height: 20px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 1.7;
  color: $black;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $grey;
    border-radius: 4px;
    background-color: $white;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 4px;
    width: 18px;
    height: 19px;
    font-size: 1.3em;
    line-height: 0.8;
    background: url('~static/images/checkmark.svg') no-repeat;
    .labeled-checkbox__checkbox:not(:checked)+& {
      opacity: 0;
    }
  }
  button {
    border: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    color: $color-primary;
    background-color: transparent;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    &:focus,
    &:hover {
      color: $color-secondary;
    }
  }
  .labeled-checkbox__checkbox:focus+&::before {
    border-color: $blue;
    box-shadow: inset 3px 3px $transparent-black;
  }
  .labeled-checkbox__checkbox:disabled+&::before {
    background-color: $grey-light;
  }
  .labeled-checkbox__checkbox:disabled+& {
    cursor: not-allowed;
  }
}

.labeled-checkbox__label-title {
  @include font('sans-serif',
  'medium');
  display: block;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 1.1;
  color: $color-secondary;
}

.labeled-checkbox__label-link {
  display: block;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 14px;
  color: $color-primary;
}
