@import '@/stylesheets/base/constants';
.user-account {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 15px 15px 30px;
  background-color: $grey-light;
}

.user-account__session {
  padding: 5px 0 20px;
}

.user-account__headline {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  color: $color-secondary;
}

@media (min-width: 800px) {
  .user-account__session {
    position: absolute;
    top: 0;
    padding: 25px 0 30px;
  }
  .user-account {
    padding-top: 115px;
    padding-left: 115px;
  }
  .user-account__content {
    max-width: 525px;
  }
  .user-account__headline {
    margin-bottom: 20px;
    font-size: 40px;
  }
}
