@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.summary__headline {
  @include font('sans-serif', 'bold');
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  color: $color-secondary;
}

.summary__text {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}

@media (min-width: 800px) {
  .summary__headline {
    margin-bottom: 20px;
    font-size: 40px;
  }
  .summary__text {
    margin-bottom: 50px;
    font-size: 19px;
  }
}
