@import '@/stylesheets/base/constants';
.user-info__link {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
  color: $color-primary;
  &:focus {
    outline: 0;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-secondary;
  }
}
