@import '@/stylesheets/base/constants';
.inbox-layout {
  display: flex;
  flex-direction: column;
  background-color: $grey-light;
}

.inbox-layout__overview {
  display: flex;
  min-height: 100vh;
}
