@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.form {
  min-width: 100%;
}

.form__legend {
  @include font('sans-serif', 'bold');
  margin-bottom: 10px;
  font-size: 26px;
  color: $color-secondary;
}

.form__info {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.3;
}

.form__fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.form__global-errors {
  margin-top: 8px;
  margin-bottom: 20px;
  border-top: 4px solid $red;
  padding-top: 8px;
  font-size: 13px;
  line-height: 1.3;
  color: $red;
}

@media (min-width: 800px) {
  .form__legend {
    margin-bottom: 20px;
    font-size: 40px;
  }
  .form__info {
    margin-bottom: 30px;
    font-size: 19px;
  }
}
