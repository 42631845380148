@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
.labeled-input {
  position: relative;
}

.labeled-input--icon {
  .labeled-input__input {
    padding-right: 60px;
  }
}

.visibility-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.labeled-input--icon-pencil {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 17px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url('~static/images/icon-pencil.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.labeled-input--prefixed {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  outline: 0;
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 24px 12px 5px;
  background-color: $white;
  cursor: text;
  &.labeled-input--focus {
    border-color: $blue;
    box-shadow: inset 3px 3px $transparent-black;
  }
}

.labeled-input--disabled {
  background-color: $grey-light;
}

.labeled-input__label {
  overflow: hidden;
  position: absolute;
  top: 16px;
  left: 12px;
  width: calc(100% - 24px);
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $grey;
  pointer-events: none;
  transition: 0.2s ease transform, 0.2s ease font-size;
}

.labeled-input__input {
  @include font('sans-serif',
  'book');
  appearance: none;
  display: block;
  width: 100%;
  height: 48px;
  outline: 0;
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 22px 12px 5px;
  font-size: 17px;
  &:focus~.labeled-input__label,
  &:not([value=''])~.labeled-input__label,
  &[type='date']~.labeled-input__label {
    transform: translate(0, -7px);
    font-size: 13px;
  }
  &:focus {
    border-color: $blue;
    box-shadow: inset 3px 3px $transparent-black;
  }
  &:disabled {
    background-color: $grey-light;
  }
}

.labeled-input__prefixed-input {
  flex-grow: 1;
  outline: 0;
  border: 0;
  padding: 0;
  font-size: 19px;
  &:focus~.labeled-input__label,
  &:not([value=''])~.labeled-input__label,
  &[type='date']~.labeled-input__label {
    transform: translate(0, -9px);
    font-size: 13px;
  }
  &:disabled {
    background-color: $grey-light;
  }
}

.labeled-input__prefix {
  opacity: 0;
  margin-top: -1px;
  padding-right: 4px;
  font-size: 19px;
  font-style: inherit;
  color: $grey;
  transition: opacity 0.2s ease;
}

.labeled-input__prefix--focused {
  opacity: 1;
}

.labeled-input__input--error {
  border-color: $red;
  &+.labeled-input__label {
    color: $red;
  }
}

.labeled-input__input--prefix {
  padding-left: 50px;
}

@media (min-width: 800px) {
  .labeled-input__label {
    top: 19px;
    font-size: 19px;
  }
  .labeled-input--prefixed {
    height: 56px;
  }
  .labeled-input__input {
    height: 56px;
    padding: 24px 12px 5px;
    font-size: 19px;
    &:focus~.labeled-input__label,
    &:not([value=''])~.labeled-input__label,
    &[type='date']~.labeled-input__label {
      transform: translate(0, -9px);
    }
  }
  .labeled-input__prefix {
    margin-top: 3px;
  }
  @-moz-document url-prefix() {
    .labeled-input__prefix {
      margin-top: 1px;
    }
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .labeled-input--prefixed {
    padding-top: 22px;
  }
  .labeled-input__prefix {
    margin-top: 2px;
  }
}
