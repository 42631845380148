@import '@/stylesheets/base/constants';
@import '@/stylesheets/base/typography.scss';
@import '@/stylesheets/base/accessibility.scss';
.modal {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $transparent-black;
  cursor: pointer;
}

.modal__box {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 4;
  top: 50%;
  left: 50%;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid $color-secondary;
  box-shadow: 0 0 4px 0 $shadow;
  transform: translate(-50%, -50%);
  background-color: $white;
  cursor: default;
}

.modal__content {
  padding: 25px 25px 30px;
}

.modal__title {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.1;
  color: $color-secondary;
}

.modal__main {
  outline: 0;
}

.modal__text {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 20px;
}

.modal__back-button {
  margin-top: 10px;
}

.modal__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 17px;
  border: 0;
  background-color: transparent;
  background-image: url('~static/images/icon-close-red.svg');
  background-repeat: no-repeat;
  background-size: 34px 17px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:focus,
  &:active,
  &:hover {
    background-position: -17px;
  }
}

.modal__close-text {
  @include visually-hidden;
}

@media (min-width: 600px) {
  .modal__content {
    padding: 30px;
  }
}

@media (min-width: 900px) {
  .modal__content {
    padding: 40px 40px 45px;
  }
  .modal__title {
    margin-bottom: 30px;
    font-size: 40px;
  }
  .modal__text {
    font-size: 19px;
    line-height: 25px;
  }
}
